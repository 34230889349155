const SmoothScroll = function () {
	const links = document.querySelectorAll('a[href^="#"]')
	const navbar = document.querySelector('nav.navbar')
	const hash = window.location.hash
	console.log('test1')

	if (hash) {
		scrollHandler(hash)
	}

	for (const link of links) {
		link.addEventListener('click', (e) => {
			e.preventDefault()
			const href = e.target.getAttribute('href')
			scrollHandler(href)
		})
	}

	function scrollHandler(href) {
		let offsetTop = document.querySelector(href).offsetTop

		if (navbar) {
			offsetTop = offsetTop - parseInt(navbar.offsetHeight)
		}

		scroll({
			top: offsetTop,
			behavior: 'smooth',
		})
	}
}

export default SmoothScroll
