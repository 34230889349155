const Navbar = function() {
	const rootEl = document.getElementsByTagName('html')[0];
	const hamburgerBtn = document.getElementById('hamburger-btn');
	const searchBtn = document.getElementById('navbar-search-btn');
	const closeSearchBtn = document.getElementById('close-navbar-search-btn');
	const languageSwitcher = document.getElementById('language-switcher');
	const searchModal = document.getElementById('search-modal');
	const subMenuTriggers = Array.from(document.querySelectorAll('nav.navbar li[aria-expanded] .menu-item-header'));

	rootEl.dataset.navbarExpanded = false;

	function closeSubMenus(excludedMenu) {
    subMenuTriggers.forEach(subMenu => {
      const parent = subMenu.closest('li[aria-expanded]');

      if(parent !== excludedMenu) {
        parent.setAttribute('aria-expanded', false);
      }
    });
	}

	function toggleSearchModal() {
		if (searchModal) {
			const searchModalMode = searchModal.getAttribute('aria-expanded') !== 'false' ? 'false' : 'true';
			searchModal.setAttribute('aria-expanded', searchModalMode);
		}
	}

	function toggleNavbar() {
		if (hamburgerBtn) {
			const navbarMode = rootEl.getAttribute('data-navbar-expanded') !== 'false' ? 'false' : 'true';
			rootEl.setAttribute('data-navbar-expanded', navbarMode);
		}
	}

	function toggleLanguageSwitcher() {
		if (languageSwitcher) {
			const languageSwitcherMode = languageSwitcher.getAttribute('aria-expanded') !== 'false' ? 'false' : 'true';
			languageSwitcher.setAttribute('aria-expanded', languageSwitcherMode);
			if(languageSwitcher.getAttribute('aria-expanded') === 'true') {
				closeSubMenus('');
			}
		}
	}

	document.body.addEventListener('click', (event) => {

		if(event.target.closest('ul.menu') || event.target.closest('#language-switcher')) {
			return;
		}else {
			closeSubMenus('');
			if(languageSwitcher) {
				if(languageSwitcher.getAttribute('aria-expanded') === 'true') {
					toggleLanguageSwitcher();
				}
			}
		}
	});

	if (hamburgerBtn) {
		hamburgerBtn.addEventListener('click', () => {
			toggleNavbar();
		});
	}

	if(subMenuTriggers) {
    subMenuTriggers.forEach(trigger => {
      trigger.addEventListener('click', (event) => {
				event.preventDefault();
        const parent = trigger.closest('li[aria-expanded]');

        if(parent) {
          let mode = (parent.getAttribute('aria-expanded') === 'true') ? true : false;
          parent.setAttribute('aria-expanded', !mode);
					closeSubMenus(parent);

					if(languageSwitcher.getAttribute('aria-expanded') === 'true') {
						toggleLanguageSwitcher();
					}
        }
      });
    });
  }

	if (languageSwitcher) {
		languageSwitcher.addEventListener('click', () => {
			toggleLanguageSwitcher();
		});
	}

	if (searchBtn) {
		searchBtn.addEventListener('click', () => {
			toggleSearchModal();

			if(rootEl.dataset.navbarExpanded === 'true') {
				toggleNavbar();
			}

			if(languageSwitcher && languageSwitcher.getAttribute('aria-expanded') === 'true') {
				toggleLanguageSwitcher();
			}
		});
	}

	if (closeSearchBtn) {
		closeSearchBtn.addEventListener('click', () => {
			toggleSearchModal();
		});
	}
};

export default Navbar;

