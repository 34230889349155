/* eslint-disable no-unused-vars */
import Navbar from './modules/navbar'
import LoadModule from './modules'
import CheckSelector from './utils/checkselector'
import './modules/objectfit'
import FontAwesome from './modules/fontawesome'
import CookieConsent from './modules/cookie-consent'
import SmoothScroll from './modules/smooth-scroll'

window.addEventListener('load', () => {
	FontAwesome()
	Navbar()
	CookieConsent()
	SmoothScroll()
	LoadModule('format-phone', CheckSelector('a[href*=tel]'))
	LoadModule('accordion', CheckSelector('.handorgel'))
	LoadModule('print-button', CheckSelector('.show-for-print'))
	LoadModule('masonry-filter', CheckSelector('#masonry-archive-block'))
	LoadModule('current-news-category-filter', CheckSelector('#current-news-category'))
})
